import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { AdvancedToolDetailView } from '../../components/AdvancedTools/AdvancedToolDetailView';
import Layout from '../../components/MenuLayout';
import { GET_ADVANCED_TOOL } from '../../queries/AdvancedTools/AdvancedTools';
import { isEmpty } from '../../utils/ObjectUtils';
import Loading from '../../components/Common/Loading';
import { AlertUI } from '../../componentsUI/Alert';

export default ({ advancedToolId }) => {
  const { data: dataTool, loading, error } = useQuery(GET_ADVANCED_TOOL, { variables: { uuid: advancedToolId } });

  const advancedTool = dataTool && dataTool.advancedTool;

  return (
    <Layout type="provider">
      {error && error.graphQLErrors && error.graphQLErrors.length && (
        <AlertUI severity="error" title="Error">{error.graphQLErrors[0].message}</AlertUI>
      )}
      {
        loading || isEmpty(dataTool)
          ? <Loading />
          : <AdvancedToolDetailView advancedTool={advancedTool} />
      }
    </Layout>
  );
};
